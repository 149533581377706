/*@import "rsuite/dist/rsuite.css";*/

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

ul,
dl {
  margin-top: 0;
  margin-bottom: 0.1rem !important;
}

ul {
  list-style: none;
}

.day-selected {
  background-color: aquamarine;
}

.calendar-todo-list-day-selected {
  list-style: none;
  padding: 0;
  text-align: left;
}

.calendar-todo-list-day-selected li {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  background-color: aquamarine;
}

.calendar-todo-list-day-selected .calendar-todo-item-badge {
  vertical-align: top;
  margin-top: 8px;
  width: 6px;
  height: 6px;
}

.calendar-todo-list {
  text-align: left;
  padding: 0;
  list-style: none;
}

.calendar-todo-list li {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.calendar-todo-list .calendar-todo-item-badge {
  vertical-align: top;
  margin-top: 8px;
  width: 6px;
  height: 6px;
}

.error_telephone {
  color: red;
}

.header-nav-link {
  display: inline !important;
}
