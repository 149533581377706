.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.vertical-center-button {
  margin: 0;
  /*position: absolute;*/
  top: 50%;
  -ms-transform: translateY(40%);
  transform: translateY(40%);
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.custom-file-button {
	input[type="file"] {
		margin-left: -2px !important;

		&::-webkit-file-upload-button {
			display: none;
		}
		&::file-selector-button {
			display: none;
		}
	}

	&:hover {
		label {
			background-color: #dde0e3;
			cursor: pointer;
		}
	}
}

.dropzone {
  border: 2px dashed #cccccc;
  border-radius: 4px;
  padding: 20px;
  text-align: center;
  cursor: pointer;
}

.dropzone.active {
  border-color: #2196f3;
}

.ul-help-page {
  text-align: -webkit-left;
}

.search-container {
  /*width: 280px;*/
  display: flex;
  flex-direction: column;
}

.search-inner {
  display: flex;
}

.dropdown-product-search {
  background-color: white;
  display: flex;
  flex-direction: column;
  border: 1px solid gray;
  width: 927px;

}

.dropdown-product-search:empty {
  border: none;
}

.dropdown-product-search-row {
  cursor: pointer;
  text-align: start;
  margin: 2px 0;
  margin-left: 10px;
}

.dropdown-product-search-row:hover {
  background-color: #ededed;
}

.td-agenda-resume-success {
  background-color: #4ebf8a !important;
  font-size: small !important;
  align-content: center !important;
}

.td-agenda-resume-warning {
  background-color: #FEE214 !important;
  font-size: small !important;
  align-content: center !important;
}

.td-agenda-resume-danger {
  background-color: #F20303 !important;
  font-size: small !important;
  align-content: center !important;
}

.td-agenda-resume-address {
  font-size: small !important;
  align-content: center !important;
}

.nav-link-client {
  color: #0a5dc2 !important;
}

.td-client-name {
  text-align: center !important;
}

.badge-bg-secondary-client {
  font-size: 0.65em !important;
}

.login-form {
  max-width: 330px;
  padding: 1rem;
}

.m-auto {
  margin: auto !important;
}

.w-100 {
  width: 100% !important;
}

.login-form-card {
  background-color: #f8f9fa;
}

.copyright {
  font-size: min(.764vw,9.57pt)
}



